import React, { useEffect, useState } from 'react';
import './Atestado.css';
import { message } from 'antd';
import api from 'components/api/api';

const CertificatePage = React.forwardRef(({ nome, days, date, reason, professionalId }, ref) => {
    const [professionalDetails, setProfessionalDetails] = useState(null);

    useEffect(() => {
        if (professionalId) {
            const fetchDetails = async () => {
                try {
                    const response = await api.get(`/professionals/${professionalId}`);
                    setProfessionalDetails(response.data);
                } catch (error) {
                    console.error("Erro ao buscar detalhes do profissional", error);
                    message.error("Erro ao buscar detalhes do profissional");
                }
            };
            fetchDetails();
        }
    }, [professionalId]);

    return (
        <div className="certificate-page" ref={ref}>
            <div className="certificate-header">Atestado Médico</div>
            <div className="certificate-body">
                <p>Informo para os devidos fins que {nome} precisa ficar afastado(a) de suas atividades por um período de {days} dias a partir de {date}. Motivo: {reason}</p>
            </div>
            <div className="certificate-signature">
                {professionalDetails && professionalDetails.assinatura && (
                    <img src={`data:image/png;base64,${professionalDetails.assinatura}`} alt="Assinatura" />
                )}
                <div className="doctor-info">
                    {professionalDetails && (
                        <>
                            {professionalDetails.nome}<br />
                            {professionalDetails.titulo}<br />
                            {professionalDetails.registro_profissional}<br /><br />
                            {professionalDetails.endereco}
                            {professionalDetails.cidade}
                            {professionalDetails.estado}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default CertificatePage;
