import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './PageBody.module.scss'; 
import Sidebar from 'components/Sidebar';

export default function PageBodySystem() {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div className={styles.content}>
        <Outlet /> 
        <Footer />
      </div>
    </div>
  );    
}

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.footer__topicos}>
                <p>Holanda Dev Software ©2023 Created by Bruno Holanda</p>
            </div>
            
        </div>
    )
} 