import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Tabs, Input, InputNumber, DatePicker, Modal, Table } from 'antd';
import api from 'components/api/api';
import { useParams, useNavigate } from 'react-router-dom';
import './ClientDetails.css';
import CertificatePage from './Atestado';
import ReactToPrint from 'react-to-print';
import ReactInputMask from 'react-input-mask';
import moment from 'moment';

const { TabPane } = Tabs;

const ClientDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [appointmentDetails, setAppointmentDetails] = useState(null);
    const [editedDetails, setEditedDetails] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [certificateData, setCertificateData] = useState({
        date: null,
        days: 1,
        reason: ''
    });
    const [appointmentHistory, setAppointmentHistory] = useState([]);
    const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
    const [professionalCredentials, setProfessionalCredentials] = useState({ matricula: '', senha: '' });
    const [professionalId, setProfessionalId] = useState(null);
    const [clientNotes, setClientNotes] = useState('');
    const [clientId, setClientId] = useState(null);



    const certificatePageRef = useRef(null);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleSaveCertificate = () => {
        console.log(certificateData);
        setIsModalVisible(false);
        message.success("Atestado emitido com sucesso!");
    };

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                // 1. Obtém os detalhes da API de agendamentos
                const appointmentResponse = await api.get(`/agendamentos/${id}`);
                const appointmentData = appointmentResponse.data;

                // 2. Obtém o "client_id" dos detalhes do agendamento
                const clientId = appointmentData.client_id;
                setClientId(clientId); // Defina o clientId no estado

                // 3. Obtém os detalhes do cliente com base no "client_id"
                const clientResponse = await api.get(`/clients/${clientId}`);
                const clientData = clientResponse.data;

                const clientNotesResponse = await api.get(`/clients/${clientId}/notes`);
                const clientNotesData = clientNotesResponse.data;
                setClientNotes(clientNotesData.notes || ''); // Defina as observações ou uma string vazia se não houver observações existentes

                // Atualiza os estados com as informações obtidas
                setAppointmentDetails(appointmentData);
                setEditedDetails(clientData);
            } catch (error) {
                console.error("Erro ao buscar detalhes", error);
                message.error("Erro ao buscar detalhes");
            }
        };
        fetchDetails();
    }, [id]);


    const convertDate = (dateStr) => {
        const parts = dateStr.split("/");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    };

    const orderByDate = (appointments) => {
        return appointments.sort((a, b) => {
            const dateA = convertDate(a.data);
            const dateB = convertDate(b.data);
            return dateB - dateA;
        });
    };




    useEffect(() => {
        const fetchAppointmentHistory = async () => {
            if (appointmentDetails && appointmentDetails.client_id) {
                try {
                    const historyResponse = await api.get(`/todos-agendamentos?client_id=${appointmentDetails.client_id}`);
                    const sortedAppointments = orderByDate(historyResponse.data);
                    setAppointmentHistory(sortedAppointments);
                } catch (error) {
                    console.error("Erro ao buscar histórico de atendimentos", error);
                    message.error("Erro ao buscar histórico de atendimentos");
                }
            }
        };
        fetchAppointmentHistory();
    }, [appointmentDetails]);


    const handleSaveChanges = async () => {
        try {
            // Verifique se client_id está presente
            if (!appointmentDetails.client_id) {
                throw new Error("ID do cliente não encontrado");
            }

            await api.put(`/clients/${appointmentDetails.client_id}`, editedDetails);
            message.success("Detalhes atualizados com sucesso!");
            setAppointmentDetails(editedDetails);
        } catch (error) {
            console.error("Erro ao atualizar detalhes", error);
            message.error("Erro ao atualizar detalhes");
        }
    }

    const handleInputChange = (key, value) => {
        setEditedDetails(prevDetails => ({
            ...prevDetails,
            [key]: value
        }));
    }

    const handleGoBack = () => {
        navigate(-1);
    }

    if (!appointmentDetails) return <p>Carregando...</p>;

    const handleCertificateInputChange = (key, value) => {
        setCertificateData(prevData => ({
            ...prevData,
            [key]: value
        }));
    };


    const columns = [
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
        {
            title: 'Horário',
            dataIndex: 'horario',
            key: 'horario',
        },
        {
            title: 'Motivo',
            dataIndex: 'motivo',
            key: 'motivo',
        },
    ];

    const handleAuthModalOpen = () => {
        setIsAuthModalVisible(true);
    };

    const handleAuthModalClose = () => {
        setIsAuthModalVisible(false);
    };

    const handleCredentialChange = (e) => {
        const { name, value } = e.target;
        setProfessionalCredentials(prevCreds => ({
            ...prevCreds,
            [name]: value
        }));
    };


    const handleAuthSubmit = async () => {
        try {
            const response = await api.post('/professionals/authenticate', {
                login: professionalCredentials.matricula,
                senha: professionalCredentials.senha
            });
            console.log("Resposta recebida:", response.data);

            if (response.data.autenticado) {
                setProfessionalId(response.data.professional_id); // Armazenando o professional_id
                handleAuthModalClose();
                handleOpenModal(); // Abre o modal do atestado após autenticação
            } else {
                message.error("Credenciais inválidas");
            }
        } catch (error) {
            console.error("Erro de autenticação", error);
            message.error("Erro na autenticação");
        }
    };

    const handleSaveNotes = async () => {
        try {
            await api.post(`/clients/${clientId}/notes`, { notes: clientNotes });
            message.success("Observações salvas com sucesso!");
        } catch (error) {
            console.error("Erro ao salvar observações", error);
            message.error("Erro ao salvar observações");
        }
    };



    return (
        <div className='clienteDetails'>
            <h1>Detalhes do Cliente</h1>
            <Button onClick={handleGoBack}>Voltar</Button>
            {/* O componente CertificatePage oculto */}
            <div style={{ display: 'none' }}>
                <CertificatePage
                    nome={appointmentDetails.nome}
                    days={certificateData.days}
                    date={certificateData.date}
                    reason={certificateData.reason}
                    professionalId={professionalId} // Passando o professionalId
                    ref={certificatePageRef}
                />
            </div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Dados Pessoais" key="1">
                    <p><b>Nome:</b> <Input value={editedDetails.nome || appointmentDetails.nome} onChange={(e) => handleInputChange('nome', e.target.value)} /></p>
                    <p><b>Data de Nascimento:</b> <Input value={editedDetails.data_nascimento || appointmentDetails.data_nascimento} onChange={(e) => handleInputChange('data_nascimento', e.target.value)} /></p>
                    <p><b>Telefone:</b>
                        <ReactInputMask
                            mask="(99) 9 9999-9999"
                            value={editedDetails.celular || appointmentDetails.celular}
                            onChange={(e) => handleInputChange('celular', e.target.value)}
                        >
                            {(inputProps) => <Input {...inputProps} />}
                        </ReactInputMask>
                    </p>
                    <p><b>Plano:</b> <Input value={editedDetails.planodental} onChange={e => handleInputChange('planodental', e.target.value)} /></p>
                    <p><b>CPF:</b>
                        <ReactInputMask
                            mask="999.999.999-99"
                            value={editedDetails.cpf || appointmentDetails.cpf}
                            onChange={(e) => handleInputChange('cpf', e.target.value)}
                            disabled={true}
                        >
                            {(inputProps) => <Input {...inputProps} disabled={true} />}
                        </ReactInputMask>
                    </p>
                    <Button onClick={handleSaveChanges}>Salvar</Button>
                </TabPane>

                <TabPane tab="Histórico de Consultas" key="2">
                    <Table columns={columns} dataSource={appointmentHistory} rowKey="id" />

                </TabPane>



                <TabPane tab="Atestados e Receitas" key="3">
                    <Button onClick={handleAuthModalOpen}>Emitir Atestado</Button>
                    <Modal
                        title="Emitir Atestado"
                        visible={isModalVisible}
                        onCancel={handleCloseModal}
                        footer={[
                            <Button key="back" onClick={handleCloseModal}>Cancelar</Button>,
                            <ReactToPrint
                                trigger={() => <Button type="primary">Emitir</Button>}
                                content={() => certificatePageRef.current}
                            />

                        ]}
                    >
                        <p>Preencha os dados para emitir uma atestado ao paciente <b>{appointmentDetails.nome}</b></p>
                        <p>
                            <b>Apartir de: </b>
                            <DatePicker format="DD/MM/YYYY" onChange={(date, dateString) => handleCertificateInputChange('date', dateString)} />
                        </p>
                        <p>
                            <b>Quantidade de dias: </b>
                            <InputNumber min={1} value={certificateData.days} onChange={value => handleCertificateInputChange('days', value)} />
                        </p>
                        <p>
                            <b>Motivo/CID:</b>
                            <Input value={certificateData.reason} onChange={e => handleCertificateInputChange('reason', e.target.value)} />
                        </p>
                    </Modal>

                </TabPane>
                <TabPane tab="Anotações" key="4">
                    <div style={{ marginBottom: '16px' }}>
                        <Input.TextArea
                            rows={4}
                            value={clientNotes}
                            onChange={(e) => setClientNotes(e.target.value)}
                            style={{ marginBottom: '8px' }}
                        />
                        <Button type='primary' onClick={handleSaveNotes}>Salvar Anotações</Button>
                    </div>
                </TabPane>
            </Tabs>
            <Modal
                title="Autenticação do Profissional"
                visible={isAuthModalVisible}
                onCancel={handleAuthModalClose}
                onOk={handleAuthSubmit}
                okText="Autenticar"
                cancelText="Cancelar"
            >
                <p>Atestados só podem ser emitidos por meio da senha definida pelo profissional em seu cadastro. Nunca compartilhe sua senha!</p>
                <p>Essa senha só pode ser alterada pela equipe de suporte.</p>
                <Input
                    placeholder="Matrícula"
                    name="matricula"
                    value={professionalCredentials.matricula}
                    onChange={handleCredentialChange}
                />
                <Input.Password
                    placeholder="Senha"
                    name="senha"
                    value={professionalCredentials.senha}
                    onChange={handleCredentialChange}
                />
            </Modal>

        </div>
    );
}

export default ClientDetails;
