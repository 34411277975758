import NotFound from "pages/NotFound";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageBody from "components/PageBody";
import Home from "pages/Home";
import Servicos from "pages/Services";
import Sobre from "pages/About";
import Contato from "pages/Contact";
import Estetica from "pages/Services/Estetica";
import Canal from "pages/Services/Canal";
import Protese from "pages/Services/Protese";
import Clinico from "pages/Services/Clinico";
import Schedule from "pages/Schedule";
import Appointments from "pages/Appointments";
import AllAppointments from "pages/Appointments/AllAppointments";
import Authentication from "pages/Auth";
import PageBodySystem from "components/PageBodySystem";
import CalendarPage from "pages/Appointments/Calendar";
import AdminSc from "pages/Appointments/AdminSc";
import ClientDetails from "pages/ClientDetails";
import Configs from "pages/Companies/Professional";
import DoctorDetails from "pages/Companies/Professional/ProfessionalDetails";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
function AppRoutes() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        setIsAuthenticated(!!token);
    }, []);

    function ProtectedRoute({ element }) {
        const token = localStorage.getItem('authToken');
        if (!token) {
            return <Navigate to="/login" replace state={{ from: window.location.pathname }} />;
        }
        return element;
    }




    return (
        <HashRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<PageBody />}>
                    <Route index element={<Home />} />
                    <Route path="/servicos" element={<Servicos />} />
                    <Route path="/sobre" element={<Sobre />} />
                    <Route path="/contato" element={<Contato />} />
                    <Route path="/agendar" element={<Schedule />} />
                    <Route path="/servicos/estetica" element={<Estetica />} />
                    <Route path="/servicos/canal" element={<Canal />} />
                    <Route path="/servicos/protese" element={<Protese />} />
                    <Route path="/servicos/clinico" element={<Clinico />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
                <Route element={<PageBodySystem />}>
                    <Route path="/login" element={<Authentication />} />
                    <Route path="/agendamentos" element={<ProtectedRoute element={<Appointments />} />} />
                    <Route path="/allagendamentos" element={<ProtectedRoute element={<AllAppointments />} />} />
                    <Route path="/calendario" element={<ProtectedRoute element={<CalendarPage />} />} />
                    <Route path="/admagenda" element={<ProtectedRoute element={<AdminSc />} />} />
                    <Route path="/client-details/:id" element={<ProtectedRoute element={<ClientDetails />} />} />
                    <Route path="/configs" element={<ProtectedRoute element={<Configs />} />} />
                    <Route path="/professionals/:id" element={<ProtectedRoute element={<DoctorDetails />} />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </HashRouter>
    )
}

export default AppRoutes;