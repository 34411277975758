import { Link } from "react-router-dom";
import styles from './Header.module.scss';
import logo from '../../../public/logo.png';
import Btn from "components/Btn";
import MenuMobile from "components/MenuMobile";
import { useState } from "react";
import { FaChevronDown } from 'react-icons/fa';


const handleWhatsAppClick = () => {
    const phoneNumber = '5583996311573'; // Coloque o número de telefone desejado aqui
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
};


function MenuLinks() {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    }

    return (
        <div className={styles.menuLinks}>
            <Link to="./">
                Início
            </Link>
            <div
                className={styles.subMenuContainer}
                onMouseEnter={() => setShowSubMenu(true)}
                onMouseLeave={() => setShowSubMenu(false)}
            >
               <Link className={styles.menuLink} to="/">
                    Serviços
                    <FaChevronDown className={styles.arrow} />
                </Link>
                {showSubMenu && (
                    <div className={styles.subMenu}>
                        <Link to="/servicos/estetica">
                            Estética
                        </Link>
                        <Link to="/servicos/canal">
                            Tratamento de Canal
                        </Link>
                        <Link to="/servicos/protese">
                            Prótese
                        </Link>
                        <Link to="/servicos/clinico">
                            Clínico Geral
                        </Link>
                    </div>
                )}
            </div>
            <Link to="./sobre">
                Sobre
            </Link>
            <Link to="./contato">
                Contato
            </Link>
            <a onClick={scrollToBottom}>
                Convênios
            </a>
        </div>
    )
}

function SocialLinks() {
    return (
        <div className={styles.socialLinks}>
            <a href="https://www.instagram.com/drawaleskacaetano_/" target="_blank" rel="noreferrer">
                <div className={styles.socialLinks__li}></div>
            </a>
            <a href="https://wa.me/5583998472670" target="_blank" rel="noreferrer">
                <div className={styles.socialLinks__wp}></div>
            </a>
        </div>
    )
}

export default function Header() {
    return (
        <div className={styles.header}>
            <Link to="./">
                <img src={logo} alt="logo da pagina" />
            </Link>
            <div className={styles.header__mobile}>
                <MenuMobile />
            </div>
            <div className={styles.header__social}>
                <SocialLinks />
            </div>
            <div className={styles.header__links}>
                <MenuLinks />
            </div>
            <Link  to='./agendar'>
            <Btn>Agendar Consulta</Btn>
            </Link>
        </div>
    )
}