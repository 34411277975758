import React, { useEffect, useRef, useState } from 'react';
import { Input, Button, DatePicker, Select, Form, message, Modal } from 'antd';
import ReactSignatureCanvas from 'react-signature-canvas';
import ReactInputMask from 'react-input-mask';
import { useForm } from 'antd/es/form/Form';
import api from '../../../components/api/api';

function ProfessionalModal({ isVisible, onClose, initialData }) {
    const [isEditMode, setIsEditMode] = useState(false);

    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [dataNascimento, setDataNascimento] = useState(null);
    const [registro, setRegistro] = useState("");
    const [numeroRegistro, setNumeroRegistro] = useState("");
    const [estados, setEstados] = useState([]);
    const [estadoSelecionado, setEstadoSelecionado] = useState(null);
    const [estadoProffisionalSelecionado, setEstadoProffisionalSelecionado] = useState(null);
    const [titulo, setTitulo] = useState(null);
    const [celular, setCelular] = useState('');
    const [login, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("");



    const [cep, setCep] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [referencia, setReferencia] = useState("");
    const [cidade, setCidade] = useState("");
    const [currentStep, setCurrentStep] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(isVisible);
    const [form] = useForm();

    const nextStep = () => {
        if (currentStep < 4) {
            setCurrentStep(prev => prev + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const validateFieldsAndNextStep = async () => {
        try {
            await form.validateFields();
            nextStep();
        } catch (error) {
            message.error('Por favor, preencha todos os campos.');
        }
    }

    const validarCPF = (cpf) => {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false;

        let soma = 0;
        let resto;

        for (let i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);

        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(cpf.substring(9, 10))) return false;

        soma = 0;
        for (let i = 1; i <= 10; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) resto = 0;
        if (resto !== parseInt(cpf.substring(10, 11))) return false;

        return true;
    };

    const handleCEPChange = async (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setCep(value);

        if (value.length === 8) {
            try {
                const response = await fetch(`https://viacep.com.br/ws/${value}/json/`);
                if (!response.ok) {
                    throw new Error(`Erro ${response.status}: ${response.statusText}`);
                }

                const data = await response.json();
                if (!data.erro) {
                    setEndereco(data.logradouro);
                    setCidade(data.localidade);
                    setEstadoSelecionado(data.uf);
                    form.setFieldsValue({
                        endereco: data.logradouro,
                        cidade: data.localidade,
                        estado: data.uf
                    });
                } else {
                    console.error("CEP não encontrado.");
                }
            } catch (error) {
                console.error("Erro ao buscar CEP:", error);
            }
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <Form.Item
                            name="nome"
                            label="Nome"
                            rules={[{ required: true, message: 'Por favor, insira o nome!' }]}
                        >
                            <Input value={nome} onChange={e => setNome(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="cpf"
                            label="CPF"
                            rules={[
                                { required: true, message: 'Por favor, insira o CPF!' },
                                {
                                    validator: (_, value) =>
                                        validarCPF(value) ? Promise.resolve() : Promise.reject(new Error('CPF inválido!')),
                                },
                            ]}
                        >
                            <ReactInputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={e => setCpf(e.target.value)}
                                beforeMaskedValueChange={(newState, oldState, userInput) => {
                                    var { value } = newState;
                                    var selection = newState.selection;
                                    var cursorPosition = selection ? selection.start : null;
                                    if (value.endsWith('-') && userInput !== '-' && cursorPosition === value.length - 1) {
                                        cursorPosition = value.length - 2;
                                    }

                                    return {
                                        value,
                                        selection: { start: cursorPosition, end: cursorPosition },
                                    };
                                }}
                            >
                                {(inputProps) => <Input {...inputProps} />}
                            </ReactInputMask>
                        </Form.Item>
                        <Form.Item
                            name="dataNascimento"
                            label="Data de Nascimento"
                            rules={[{ required: true, message: 'Por favor, insira a data de nascimento!' }]}
                        >
                            <DatePicker format="DD/MM/YYYY" onChange={setDataNascimento} />
                        </Form.Item>
                        <Form.Item
                            name="celular"
                            label="Celular"
                            rules={[{ required: true, message: 'Por favor, insira seu número de celular!' }]}
                        >
                            <ReactInputMask
                                mask="(99) 9 9999-9999"
                                value={celular}
                                onChange={e => setCelular(e.target.value)}
                                placeholder="(99) 9 9999-9999"
                            >
                                {(inputProps) => <Input {...inputProps} type="tel" />}
                            </ReactInputMask>
                        </Form.Item>

                    </>
                );
            case 1:
                return (
                    <>
                        <Form.Item
                            name="registro"
                            label="Registro"
                            rules={[{ required: true, message: 'Por favor, selecione o registro!' }]}
                        >
                            <Select onChange={setRegistro} placeholder="Selecione CRM ou CRO">
                                <Select.Option value="CRM">CRM</Select.Option>
                                <Select.Option value="CRO">CRO</Select.Option>
                            </Select>
                        </Form.Item>

                        {registro && (
                            <Form.Item
                                name="numeroRegistro"
                                label={`Número do ${registro}`}
                                rules={[{ required: true, message: `Por favor, insira o número do ${registro}!` }]}
                            >
                                <Input value={numeroRegistro} onChange={e => setNumeroRegistro(e.target.value)} />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="estadoProfissional"
                            label="Estado"
                            rules={[{ required: true, message: 'Por favor, selecione um estado!' }]}
                        >
                            <Select value={estadoProffisionalSelecionado} onChange={setEstadoProffisionalSelecionado} placeholder="Selecione um estado">
                                {estados.map(estado => (
                                    <Select.Option key={estado.id} value={estado.sigla}>{estado.sigla}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="titulo"
                            label="Título"
                            rules={[{ required: true, message: 'Por favor, insira um Título, Ex. Cirugião Plástico' }]}
                        >
                            <Input value={titulo} onChange={e => setTitulo(e.target.value)} />
                        </Form.Item>
                    </>
                );
            case 2:
                return (
                    <>
                        <Form.Item
                            name="cep"
                            label="CEP"
                            rules={[{ required: true, message: 'Por favor, insira o CEP!' }]}
                        >
                            <Input value={cep} onChange={handleCEPChange} maxLength={8} />
                        </Form.Item>

                        <Form.Item
                            name="endereco"
                            label="Endereço"
                            rules={[{ required: true, message: 'Por favor, insira o endereço!' }]}
                        >
                            <Input value={endereco} onChange={e => setEndereco(e.target.value)} />
                        </Form.Item>

                        <Form.Item
                            name="numero"
                            label="Número"
                            rules={[{ required: true, message: 'Por favor, insira o número!' }]}
                        >
                            <Input value={numero} onChange={e => setNumero(e.target.value)} />
                        </Form.Item>

                        <Form.Item
                            name="referencia"
                            label="Referência"
                            rules={[{ required: true, message: 'Por favor, insira a referência!' }]}
                        >
                            <Input value={referencia} onChange={e => setReferencia(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="estado"
                            label="Estado"
                            rules={[{ required: true, message: 'Por favor, selecione um estado!' }]}
                        >
                            <Select value={estadoSelecionado} onChange={setEstadoSelecionado} placeholder="Selecione um estado">
                                {estados.map(estado => (
                                    <Select.Option key={estado.id} value={estado.sigla}>{estado.sigla}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="cidade"
                            label="Cidade"
                            rules={[{ required: true, message: 'Por favor, insira a cidade!' }]}
                        >
                            <Input value={cidade} onChange={e => setCidade(e.target.value)} />
                        </Form.Item>


                    </>
                );
                case 3:
                return (
                    <>
                        <Form.Item
                            name="login"
                            label="Login"
                            rules={[{ required: true, message: 'Por favor, insira um login!' }]}
                        >
                            <Input value={login} onChange={e => setLogin(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="senha"
                            label="Senha"
                            rules={[
                                { required: true, message: 'Por favor, insira uma senha!' },
                                { min: 6, message: 'A senha deve ter pelo menos 6 caracteres' }
                            ]}
                        >
                            <Input.Password value={senha} onChange={e => setSenha(e.target.value)} />
                        </Form.Item>
                        <Form.Item
                            name="confirmacaoSenha"
                            label="Confirmação da Senha"
                            dependencies={['senha']}
                            hasFeedback
                            rules={[
                                { required: true, message: 'Por favor, confirme sua senha!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('senha') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('As senhas não correspondem!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password value={confirmacaoSenha} onChange={e => setConfirmacaoSenha(e.target.value)} />
                        </Form.Item>
                    </>
                );
            case 4:
                return (
                    <>
                        <Form.Item label="Assinatura">
                            <div style={{ border: '1px solid black', width: 500, marginBottom: '10px' }}>
                                <ReactSignatureCanvas
                                    ref={sigCanvas}
                                    canvasProps={{
                                        width: 500,
                                        height: 200,
                                        className: 'signatureCanvas'
                                    }}
                                />
                            </div>
                            <Button onClick={clearSignature}>Limpar</Button>
                            <Button onClick={saveSignature} style={{ marginLeft: '10px' }}>Salvar Assinatura</Button>
                        </Form.Item>
                    </>
                );
            
            default:
                return null;
        }
    };

    const handleSubmit = async () => {
        try {
            const company_id = localStorage.getItem('companyID');
            if (!company_id) {
                message.error('ID da empresa não encontrado. Faça o login novamente.');
                return;
            }

            const registroProfissional = `${registro} ${estadoProffisionalSelecionado} - ${numeroRegistro}`;
            const signatureWithPrefix = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            const signatureBase64 = signatureWithPrefix.split(',')[1];

            const professionalData = {
                nome,
                cpf,
                data_de_nascimento: dataNascimento.format('DD/MM/YYYY'),
                celular,
                registro_profissional: registroProfissional,
                titulo,
                assinatura: signatureBase64,
                cep,
                endereco,
                numero,
                referencia,
                cidade,
                estado: estadoSelecionado,
                company_id,
                login,
                senha
            };

            if (isEditMode) {
                await api.put(`/professionals/${initialData.id}`, professionalData);
                message.success('Profissional atualizado com sucesso!');
            } else {
                await api.post('/professionals', professionalData);
                message.success('Profissional salvo com sucesso!');
                onClose(); 
            }
        } catch (error) {
            console.error("Erro ao salvar profissional:", error);
            if (error.response) {
                message.error(`Erro ao salvar profissional: ${error.response.status} ${error.response.statusText}`);
            } else {
                message.error('Erro ao salvar profissional');
            }
        }
    };


    const [signatureData, setSignatureData] = useState("");
    const sigCanvas = useRef(null);

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
            setSignatureData("");
        }
    };

    const saveSignature = () => {
        if (sigCanvas.current) {
            // Pega a assinatura em base64 com o prefixo
            const signatureWithPrefix = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            // Remove o prefixo para obter apenas o código base64
            const signatureBase64 = signatureWithPrefix.split(',')[1];
            setSignatureData(signatureBase64);
            message.success('Assinatura salva com sucesso!');
        }
    };


    useEffect(() => {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
            .then(response => response.json())
            .then(data => setEstados(data))
            .catch(error => console.error('Erro ao buscar estados:', error));
    }, []);

    useEffect(() => {
        setIsModalVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        if (initialData) {
            form.setFieldsValue(initialData);
        } else {
            form.resetFields();
        }
    }, [initialData, form]);


    return (
        <Modal
            title={initialData ? 'Editar Profissional' : 'Adicionar Profissional'}
            visible={isModalVisible}
            onCancel={onClose}
            footer={null}
            width={800}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        handleSubmit(values);
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info);
                    });
            }}        >
            <Form layout="vertical" form={form}>

                {renderStepContent()}

                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                    {currentStep > 0 && (
                        <Button onClick={prevStep}>
                            Anterior
                        </Button>
                    )}
                    {currentStep < 4 ? (
                        <Button type="default" onClick={validateFieldsAndNextStep}>
                            Próxima
                        </Button>
                    ) : (
                        <Button type="primary" onClick={handleSubmit}>
                            Salvar Cadastro
                        </Button>
                    )}
                </div>
            </Form>
        </Modal>
    );
}

export default ProfessionalModal;