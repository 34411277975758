import { Link, Outlet } from "react-router-dom";
import Header from "./Header";
import styles from './PageBody.module.scss';
import FloatingWhatsAppButton from "components/FloatingButton";

export default function PageBody() {
    return (
        <>
            <Header />
            <div className={styles.risco}></div>
            <Outlet />
            <Footer />
            <FloatingWhatsAppButton />
        </>
    )
}

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.footer__topicos}>
                <h3>Informacões ao Paciente</h3>
                <div className={styles.link}>
                    <Link to="/sobre">
                        Sobre a Dentista
                    </Link>
                    <Link to="/servicos/estetica">
                        Antes e Depois
                    </Link>
                    <Link to="/">
                        Depoimentos
                    </Link>
                </div>
            </div>
            <div className={styles.footer__topicos}>
                <h3>Servicos</h3>
                <div className={styles.link}>
                    <Link to="/servicos/clinico">
                        Prevencao
                    </Link>
                    <Link to="/servicos/canal">
                        Canal
                    </Link>
                    <Link to="/servicos/estetica">
                        Clareamento
                    </Link>
                    <Link to="/servicos/clinico">
                        Emergencia
                    </Link>
                </div>
            </div>
            <div className={styles.footer__topicos}>
                <h3>Contato</h3>
                <p> R. Alm. Barroso, 438 - Sala 111 - Centro, João Pessoa - PB</p>
                <p> 83 99631-1573</p>
                <a href="mailto:drawaleskacaetano@gmail.com?subject=Contato">drawaleskacaetano@gmail.com</a>
            </div>
        </div>
    )
} 