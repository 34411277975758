// CompanyDataModal.js
import React, { useEffect, useState } from 'react';
import { Modal, Spin } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import api from '../../../components/api/api';

const CompanyDataModal = ({ isVisible, onClose }) => {
    const [companyData, setCompanyData] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchCompanyData = async () => {
            const storedCompanyID = localStorage.getItem('companyID');
            const token = localStorage.getItem('authToken');
    
            if (storedCompanyID && token) {
                setLoading(true);
                try {
                    const response = await api.get(`/companies/${storedCompanyID}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        },
                    });
    
                    setCompanyData(response.data); 
                } catch (error) {
                    if (error.response) {
                        console.error('API response error:', error.response);
                    } else {
                        console.error('Error fetching company data:', error);
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                console.error('Company ID or auth token not found in local storage');
            }
        };
    
        if (isVisible) {
            fetchCompanyData();
        }
    }, [isVisible]);
    


    return (
        <Modal
            title="Dados da Empresa"
            visible={isVisible}
            onCancel={onClose}
            footer={null}
        >
            {loading ? <Spin /> : (
                <>
                    <p><strong>Nome:</strong> {companyData.nome}</p>
                    <p><strong>CNPJ:</strong> {companyData.cnpj}</p>
                    <p>Estes dados estão sob responsabilidade do gestor do sistema, para alterações favor contatar o suporte Online <WarningTwoTone twoToneColor="#ff0000" /></p>
                </>
            )}
        </Modal>
    );
};

export default CompanyDataModal;
