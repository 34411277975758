import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../calendar.css';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';


moment.updateLocale('pt-br', {
    longDateFormat: {
        LT: 'HH:mm ', // Formato de 24 horas
        LTS: 'HH:mm:ss',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: 'MMMM D, YYYY HH:mm',
        LLLL: 'dddd, MMMM D, YYYY HH:mm'
    }
});

const localizer = momentLocalizer(moment)

const Event = ({ event, onEventClick }) => (
    <Tooltip
        title={<CustomTooltip appointment={event} onConfirm={() => onEventClick(event)} />}
        placement="top"
    >
        <span>{event.title}</span>
    </Tooltip>
);

const CustomTooltip = ({ appointment, onConfirm }) => {
    return (
        <div className="custom-tooltip">
            <p>{appointment.nome}</p>
            <Button type='primary' onClick={() => onConfirm()}>Confirmar/Reagendar</Button>
            <Link to={`/client-details/${appointment.id}`}>
                <Button type='primary'>Sobre o Paciente</Button>
            </Link>
        </div>
    );
};


const CalendarView = ({ events, onEventClick }) => {
    const handleSelectEvent = (event) => {
        onEventClick(event);
    };
    const minTime = new Date();
    minTime.setHours(7, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(22, 0, 0);

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = '#EEAD2D';
        if (event.status === 2) {
            backgroundColor = 'red';
        } else if (event.status === 1) {
            backgroundColor = 'green';
        }

        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '6px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };

        return {
            style: style
        };
    };

    const CustomHeader = ({ label }) => (
        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {label}
        </div>
    );




    return (
        <div style={{ height: 1200 }}>
            <Calendar
                localizer={localizer}
                events={events}
                onSelectEvent={handleSelectEvent}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                defaultDate={moment().toDate()}
                defaultView="work_week"
                views={['work_week']}
                
                components={{
                    header: CustomHeader,
                    event: ({ event }) => (
                        <Event event={event} onEventClick={onEventClick} />
                    ),                }}
                min={minTime} // Define o horário de início do calendário
                max={maxTime} // Define o horário de encerramento do calendário
                timeslots={1} // Define o intervalo de tempo (em horas) para cada slot. O padrão é 2, mas você pode ajustar de acordo com sua necessidade.
                step={60} // Define o intervalo (em minutos) entre os slots. Padrão é 30.
                timeGutterFormat={{ format: 'HH:mm' }} // Especifica o formato de exibição para a coluna de tempo (gutter)
            />
        </div>
    );
}

export default CalendarView;
