import amil from '../../public/convenios/amil.webp';
import bradesco from '../../public/convenios/bradesco.jpg';
import dentalgold from '../../public/convenios/gold.png';
import brasil from '../../public/convenios/bb.webp';
import santander from '../../public/convenios/santander.webp';
import prev from '../../public/convenios/odontoprev.jpg';
import serv from '../../public/convenios/odontoserv.jpg';
import system from '../../public/convenios/system.webp';
import styles from './Convenios.module.scss';

export default function Convenios() {

    const empresas = [
        { nome: "Bradesco Dental", imagem: bradesco },
        { nome: "BB Dental", imagem: brasil },
        { nome: "Dental Gold", imagem: dentalgold },
        { nome: "Odonto Prev", imagem: prev },
    ];

    return (
        <section className={styles.convenios}>
            <h2>Convênios atendidos</h2>
            <div className={styles.convenios__icones}>
                {empresas.map((empresa, index) => (
                    <div key={index}>
                        <img src={empresa.imagem} alt={empresa.nome} />
                        <p>{empresa.nome}</p>
                    </div>
                ))}
            </div>
        </section>
    )
}