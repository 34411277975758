import { useState } from 'react';
import styles from './Contact.module.scss';
import Btn from 'components/Btn';
import Convenios from 'components/Convenios';

export default function Contato() {
  return (
    <section className={styles.contato}>
      <div>
        <h2>Entre em contato</h2>
        <h3>E-mail:</h3>
        <p>drawaleskacaetano@gmail.com</p>
        <h3>Telefone</h3>
        <p>Telefone: (83) 3034-0608</p>
        <h3>Whatsapp</h3>
        <p>Whatsapp: (83) 99631-1573</p>
      </div>
      <div>
        <h2>Se preferir Use o Formulario</h2>
        <FormularioContato />
      </div>
      <Mapa />
      <Convenios />
    </section>
  )
}

function FormularioContato() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    const url = `mailto:casaamorevida@outlook.com?subject=ContatoSite&body=${mensagem}`;
    window.open(url);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formulario}>
        <div className={styles.formulario__campo}>
          <label>Nome</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} placeholder='Seu Nome e Sobrenome' />
        </div>
        <div className={styles.formulario__campo}>
          <label>Email</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='exemplo@email.com' />
        </div>
      </div>
      <div className={styles.formulario__mensagem}>
        <label>Como posso te ajudar?</label>
        <textarea value={mensagem} onChange={(e) => setMensagem(e.target.value)} />
        <div className={styles.botao}>
          <Btn type="submit">Enviar</Btn>
        </div>
      </div>
    </form>
  );
}

function Mapa() {
  return (
    <section className={styles.mapa}>
      <h2>Meu local de Atendimento</h2>
      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.04994962271!2d-34.879490113090355!3d-7.120210151461518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ace71d2c1182ed%3A0x144e11b63e34417e!2sConsult%C3%B3rio%20Dra%20Waleska%20Caetano!5e0!3m2!1spt-BR!2sbr!4v1690843074561!5m2!1spt-BR!2sbr"
          width="100%"
          height="320"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Localização do Consultório Dra. Waleska Caetano"
        />
      </div>
    </section>
  )
}
