import React, { useState, useEffect, useRef } from 'react';
import { message, Button, Tabs, Input } from 'antd';
import api from 'components/api/api';
import { useParams, useNavigate } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import '../../../Appointments/Appointments.css';
import { UserOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const DoctorDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [professionalDetails, setProfessionalDetails] = useState(null);
    const [editedDetails, setEditedDetails] = useState({});
    const [signatureData, setSignatureData] = useState("");
    const sigCanvas = useRef({});



    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const response = await api.get(`/professionals/${id}`);
                console.log("Dados recebidos:", response.data);
                setProfessionalDetails(response.data);
                setEditedDetails(response.data);
            } catch (error) {
                console.error("Erro ao buscar detalhes", error);
                message.error("Erro ao buscar detalhes");
            }
        };
        
        fetchDetails();
    }, [id]);



    const handleSaveChanges = async () => {
        try {
            const response = await api.put(`/professionals/${id}`, editedDetails);

            if (response.status === 200) {
                message.success("Detalhes atualizados com sucesso!");
                setProfessionalDetails(editedDetails);
            } else {
                console.error('Resposta de erro da API:', response);
                message.error("Erro ao atualizar detalhes: " + response.statusText);
            }
        } catch (error) {
            console.error("Erro ao atualizar detalhes", error);
            message.error("Erro ao atualizar detalhes: " + error.message);
        }
    }


    const handleInputChange = (key, value) => {
        setEditedDetails(prevDetails => ({
            ...prevDetails,
            [key]: value
        }));
    };


    const handleGoBack = () => {
        navigate(-1);
    }

    if (!professionalDetails) return <p>Carregando...</p>;

    const handleUpdateSignature = async (newSignatureBase64) => {
        try {
            const payload = {
                ...professionalDetails, 
                assinatura: newSignatureBase64 
            };

            console.log('Enviando assinatura:', payload); 
            const response = await api.put(`/professionals/${id}`, payload);

            if (response.status === 200) {
                message.success("Assinatura atualizada com sucesso!");
                setProfessionalDetails({
                    ...professionalDetails,
                    assinatura: newSignatureBase64
                });
                clearSignature();
            } else {
                message.error("Não foi possível atualizar a assinatura.");
            }
        } catch (error) {
            console.error("Erro ao atualizar a assinatura", error);
            message.error("Erro ao atualizar a assinatura");
        }
    };

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
            setSignatureData("");
        }
    };

    const saveSignature = () => {
        if (sigCanvas.current) {
            const signatureWithPrefix = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            const signatureBase64 = signatureWithPrefix.split(',')[1];
            setSignatureData(signatureBase64);
            handleUpdateSignature(signatureBase64);
        }
    };



    return (
        <div className='tabela'>
            <h1>Detalhes do Proffisional <UserOutlined /></h1>
            <Button onClick={handleGoBack}>Voltar</Button>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Dados Pessoais" key="1">
                    <p><b>Nome:</b> <Input value={editedDetails.nome || professionalDetails.nome} onChange={(e) => handleInputChange('nome', e.target.value)} /></p>
                    <p><b>Telefone:</b> <Input value={editedDetails.celular || professionalDetails.celular} onChange={(e) => handleInputChange('celular', e.target.value)} /></p>
                    <p><b>Nascimento:</b> <Input value={editedDetails.data_de_nascimento || professionalDetails.data_de_nascimento} onChange={e => handleInputChange('data_de_nascimento', e.target.value)} /></p>
                    <p><b>CPF:</b> <Input value={editedDetails.cpf || professionalDetails.cpf} onChange={e => handleInputChange('cpf', e.target.value)} /></p>
                    <Button onClick={handleSaveChanges}>Salvar</Button>
                </TabPane>
                <TabPane tab="Informacoes Proffisionais" key="2">
                    <p><b>Registro Profissional:</b> <Input value={editedDetails.registro_profissional || professionalDetails.registro_profissional} onChange={(e) => handleInputChange('registro_profissional', e.target.value)} /></p>
                    <p><b>Título:</b> <Input value={editedDetails.titulo || professionalDetails.titulo} onChange={(e) => handleInputChange('titulo', e.target.value)} /></p>
                    <Button onClick={handleSaveChanges}>Salvar</Button>
                </TabPane>
                <TabPane tab="Endereco de Atendimento" key="4">
                    <p><b>CEP:</b> <Input value={editedDetails.cep || professionalDetails.cep} onChange={(e) => handleInputChange('cep', e.target.value)} /></p>
                    <p><b>Endereço:</b> <Input value={editedDetails.endereco || professionalDetails.endereco} onChange={(e) => handleInputChange('endereco', e.target.value)} /></p>
                    <p><b>Número:</b> <Input value={editedDetails.numero || professionalDetails.numero} onChange={e => handleInputChange('numero', e.target.value)} /></p>
                    <p><b>Referência:</b> <Input value={editedDetails.referencia || professionalDetails.referencia} onChange={e => handleInputChange('referencia', e.target.value)} /></p>
                    <p><b>Estado:</b> <Input value={editedDetails.estado || professionalDetails.estado} onChange={e => handleInputChange('estado', e.target.value)} /></p>
                    <p><b>Cidade:</b> <Input value={editedDetails.cidade || professionalDetails.cidade} onChange={e => handleInputChange('cidade', e.target.value)} /></p>
                    <Button onClick={handleSaveChanges}>Salvar</Button>
                </TabPane>
                <TabPane tab="Assinatura" key="5">
                    <p><b>Assinatura Atual:</b></p>
                    {professionalDetails.assinatura && (
                        <img src={`data:image/png;base64,${professionalDetails.assinatura}`} alt="Assinatura" />
                    )}
                    <p><b>Você pode assinar novamnete para atualizar:</b></p>

                    <div style={{ border: '1px solid black', width: 500, margin: '20px' }}>

                        <ReactSignatureCanvas
                            ref={sigCanvas}
                            canvasProps={{
                                width: 500,
                                height: 200,
                                className: 'signatureCanvas'
                            }}
                        />
                    </div>
                    <Button onClick={clearSignature}>Limpar</Button>
                    <Button onClick={saveSignature} style={{ marginLeft: '10px' }}>Salvar Assinatura</Button>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default DoctorDetails;
