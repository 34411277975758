import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.scss';
import cadeado from '../../public/cadeado.png';
import { BASE_URL } from 'config';
import Btn from 'components/Btn';
import { message } from 'antd';

const Authentication = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyID, setCompanyID] = useState(''); // Este estado deve conter o ID da empresa

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      // Suponha que `companyID` seja o ID da empresa que você deseja associar ao novo usuário.
      const payload = { username, password, company_id: companyID };

      const response = await fetch(`${BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error);
      }

      navigate('/login');
    } catch (error) {
      alert(error.message);
    }

    setUsername('');
    setPassword('');
    setCompanyID(''); // Limpe o ID da empresa também, se aplicável

  };


  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Erro ao fazer login.');
      }

      const { token, company_id } = await response.json();
      console.log('Logged in company ID:', company_id); // Isto deve mostrar o company_id no console

      if (typeof company_id === 'undefined') {
        throw new Error('Informações do usuário ou da empresa não estão disponíveis.');
      }

      localStorage.setItem('authToken', token);
      localStorage.setItem('companyID', company_id);

      // Agora, você pode opcionalmente buscar informações adicionais da empresa, se necessário
      // ...

      setUsername('');
      setPassword('');
      navigate('/agendamentos');
    } catch (error) {
      message.error(error.message);
    }
  };
  


  return (
    <div className={styles.autenticar}>
      <div className={styles.icone}>
        <h1>Acesso ao Painel de Agendamentos</h1>
        <img src={cadeado} alt="imagem de cadeado" />
      </div>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Usuário</label>
          <input
            type="text"
            id="username"
            placeholder='Usuário'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            placeholder='Senha'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Btn type="submit">Entrar</Btn>
      </form>
      <h2>Registro</h2>
      <form onSubmit={handleRegister}>
        <div>
          <label htmlFor="register-username">Usuário:</label>
          <input
            type="text"
            id="register-username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>   
          <label htmlFor="register-password">Senha:</label>
          <input
            type="password"
            id="register-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>   
          <label htmlFor="company-id">Codigo da Empresa:</label>
          <input
            type="text"
            id="company-id"
            value={companyID}
            onChange={(e) => setCompanyID(e.target.value)}
          />
        </div>
        <button type="submit">Registrar</button>
      </form>
    </div>
  );
};

export default Authentication;
