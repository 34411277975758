import React, { useState } from 'react';
import {
  HistoryOutlined,
  ScheduleOutlined,
  LogoutOutlined,
  CalendarOutlined,
  ControlOutlined,
  SettingOutlined, 
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu, theme } from 'antd';

const { Header, Sider } = Layout;

function getItem(label, key, icon, children, url) {
  return {
    key,
    icon,
    children,
    label: url ? <Link to={url}>{label}</Link> : label,
  };
}

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate(); 
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isAuthenticated = !!localStorage.getItem('authToken');

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const onMenuClick = (e) => {
    if (e.key === 'logout') {
      handleLogout();
    }
  };

  const iconSize = 22; // Defina o tamanho desejado dos ícones


  const items = [
    getItem('Agendamentos', '1', <ScheduleOutlined style={{ fontSize: iconSize }} />, null, '/agendamentos'),
    getItem('Histórico', '2', <HistoryOutlined style={{ fontSize: iconSize }} />, null, '/allagendamentos'),
    getItem('Calendário', '3', <CalendarOutlined style={{ fontSize: iconSize }} />, null, '/calendario'),
    getItem('Controle da Agenda', '4', <ControlOutlined style={{ fontSize: iconSize }} />, null, '/admagenda'),
    getItem('Profissionais', '5', <SettingOutlined style={{ fontSize: iconSize }} />, null, '/configs'),
    isAuthenticated && getItem('Sair do Sistema', 'logout', <LogoutOutlined style={{ fontSize: iconSize }} />, null, null), // Conditional item
  ].filter(Boolean);

  if (!isAuthenticated) {
    return null;
  }

  
  return (
    <Layout style={{ minHeight: '100vh' }}>
    <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        defaultSelectedKeys={['1']}
        mode="inline"
        items={items}
        onClick={onMenuClick}
        className="custom-menu" // Adicione uma classe personalizada ao Menu
      />
    </Sider>
    <Layout>
      <Header style={{ padding: 0, background: colorBgContainer }} />
    </Layout>
  </Layout>
  );
};

export default Sidebar;
