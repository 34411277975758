import styles from './About.module.scss';
import dentista from '../../public/dentista-1.webp';
import consultorio from '../../public/consultorio-1.jpg'
import especialidades from '../../public/dentista-3.jpg';
import Convenios from 'components/Convenios';
import { Helmet } from 'react-helmet';

export default function Sobre() {
    const professionalData = {
        "@context": "http://schema.org",
        "@type": "Person",
        "name": "Waleska Caetano",
        "jobTitle": "Dentista",
        "image": dentista,
        "description": "Waleska é uma profissional dedicada e especialista em proporcionar uma experiência confortável e sem dor para seus pacientes. Com sua habilidade excepcional, ela é capaz de explicar de forma clara e compreensível cada procedimento realizado, garantindo que os pacientes entendam completamente o que está acontecendo em seus tratamentos dentários."
    };

    const officeData = {
        "@context": "http://schema.org",
        "@type": "LocalBusiness",
        "name": "Consultório da Dra. Waleska Caetano",
        "image": consultorio,
        "description": "Meu consultório se destaca pela sua abordagem única, que combina tecnologia em um ambiente acolhedor, desde a recepção o consultorio conta com um espaço moderno que promove a sensação de bem-estar."
    };

    const specialtyData = {
        "@context": "http://schema.org",
        "@type": "MedicalSpecialty",
        "name": "Endodontia, Prótese Fixa, Ortodontia",
        "image": especialidades,
        "description": "Especializada em Endodontia pela Faculdade COESP. Profissional em Prótese Fixa e mantenho meus conhecimentos atualizados em Ortodontia pelo Instituto Mario Leonardo."
    };
    return (
        <section className={styles.sobre}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(professionalData)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(officeData)}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(specialtyData)}
                </script>
            </Helmet>
            <Profissional />
            <Consultorio />
            <Especialidades />
            <Convenios />
        </section>
    )
}



function Profissional() {
    return (
        <section className={styles.profissional}>
            <div>
                <img src={dentista} alt="melhor dentista em joao pessoa" />
            </div>
            <div className={styles.profissional__profissional}>
                <h2>Waleska é uma profissional que você pode confiar !</h2>
                <p> Uma profissional dedicada e especialista em proporcionar uma experiência confortável e sem dor para seus pacientes. Com sua habilidade excepcional, ela é capaz de explicar de forma clara e compreensível cada procedimento realizado, garantindo que os pacientes entendam completamente o que está acontecendo em seus tratamentos dentários.</p>
                <p> Sua abordagem cuidadosa e atenciosa cria um ambiente acolhedor, no qual o paciente se sente seguro e confiante em receber os melhores cuidados odontológicos. Com essa dentista, você encontrará uma combinação perfeita entre um profissional experiente, um especialista em sua área e alguém que prioriza o bem-estar do paciente em todos os aspectos do tratamento.</p>
            </div>
        </section>
    )
}


function Consultorio() {
    return (
        <section className={styles.profissional}>
            <div className={styles.profissional__consultorio}>
                <h2>Onde Tecnologia e Conforto se Encontram</h2>
                <p>Meu consultório se destaca pela sua abordagem única, que combina tecnologia em um ambiente acolhedor, desde a recepção o consultorio conta com um espaço moderno que promove a sensação de bem-estar.</p>
                <p>Sou treinada para fornecer um atendimento personalizado, garantindo que cada paciente se sinta valorizado e confortável durante a visita, uma abordagem centrada no paciente que proporciona uma experiencia única.</p>
            </div>
            <div>
                <img src={consultorio} alt="consultorio de odontologia em joao pessoa" />
            </div>
        </section>
    )
}

function Especialidades() {
    return (
        <section className={styles.profissional}>
            <div>
                <img src={especialidades} alt="melhor dentista em joao pessoa" />
            </div>
            <div className={styles.profissional__profissional}>
                <h2>Especialidades para você confiar mais</h2>
                <p>Especializada em Endodontia pela Faculdade COESP. Sua expertise em tratamentos de canal reflete uma profunda compreensão da saúde bucal, proporcionando resultados notáveis e ganhando a confiança dos pacientes.</p>
                <p>Profissional em Prótese Fixa e mantenho meus conhecimentos atualizados em Ortodontia pelo Instituto Mario Leonardo. Essa combinação de especialidades garante um atendimento completo, capaz de restaurar a funcionalidade e a estética dentária, além de oferecer as mais modernas soluções para alinhamento dentário.</p>
            </div>
        </section>
    )
}