import banner from '../../public/banner.webp';
import styles from './Home.module.scss';
import canal from '../../public/canal-1.webp';
import clarear from '../../public/clarear.webp';
import dentista from '../../public/dentista-1.webp';
import clarear1 from '../../public/carrossel/clarear-01.webp';
import clarear2 from '../../public/carrossel/clarear-02.webp';
import clarear3 from '../../public/carrossel/clarear-03.jpg';
import agenda from '../../public/icons/agenda.png';
import depoimentos from '../../public/depoimentos.jpg';
import Btn from 'components/Btn';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carouselStyles.css';
import { Link } from 'react-router-dom';
import Convenios from 'components/Convenios';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';


export default function Home() {
    return (
        <div className={styles.home}>
            <Banner />
            <Canal />
            <Clareamento />
            <ImageCarousel />
            <Profissional />
            <Depoimentos />
            <Convenios />
        </div>
    )
}

const handleWhatsAppClick = () => {
    const phoneNumber = '5583996311573'; // Coloque o número de telefone desejado aqui
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
};

function Banner() {
    return (
        <div className={styles.banner}>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "Dentist",
                        "name": "Waleska Caetano Dentista",
                        "telephone": "5583996311573",
                        "areaServed": "João Pessoa",
                        "image": "https://waleskacaetano.com.br/static/media/consultorio-1.8c49e51d2e1ea9581a03.jpg",
                        "priceRange": "$150 a $200",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "R. Alm. Barroso, 438 - Sala 111 - Centro",
                            "addressLocality": "João Pessoa",
                            "addressRegion": "PB",
                            "addressCountry": "BR",
                            "postalCode": "Código Postal"
                        },
                        "openingHours": "08:00 as 16:00",
                        "url": "https://waleskacaetano.com.br/"
                    })}
                </script>
            </Helmet>

            <div className={styles.texto}>
                <h1>Não Espere Sentir Dor Para Ir Até o Dentista !</h1>
                <div className={styles.texto__agenda}>
                    <img src={agenda} alt="agende sua consulta com o dentista em joao pessoa" />
                    <Btn onClick={handleWhatsAppClick}>Agende sua Consulta</Btn>
                </div>
            </div>
            <img src={banner} alt="sorriso da dentista" />
        </div>
    )
}

function Canal() {
    return (
        <section className={styles.canal}>
            <div>
                <img src={canal} alt="tratamento de canal em joao pessoa" />
            </div>
            <div>
                <h2>Especialista em Tratamento de Canal</h2>
                <p>A Endodontia desempenha um papel fundamental na preservação da saúde bucal, pois trata das enfermidades que afetam a polpa dentária, a parte interna do dente que contém nervos, vasos sanguíneos e tecido conjuntivo. Quando a polpa é danificada por cárie, trauma ou infecção, podem ocorrer sintomas dolorosos, como sensibilidade, dor aguda ou um incômodo persistente. É nesse momento que a intervenção de um especialista em canal se torna crucial.</p>
                <Link to='servicos/canal'>
                    <Btn>Saiba mais</Btn>
                </Link>
            </div>
        </section>
    )
}

function Clareamento() {
    return (
        <section className={styles.clarear}>
            <div className={styles.clarear__texto}>
                <h2>Especialista em Clareamento dentario</h2>
                <p>Tratamento seguro e conservador que devolve a harmonia do seu sorriso , contribuindo com a estética e autoestima do paciente sem causar danos à estrutura dental.</p>
                <p>Oferecemos um clareamento dental avançado para remover manchas e descolorações, proporcionando um sorriso mais brilhante. Nossa equipe de especialistas se dedica a fornecer um tratamento personalizado, adaptado às necessidades e objetivos de cada paciente.</p>
                <Link to='servicos/estetica'>
                    <Btn>Saiba mais</Btn>
                </Link>
            </div>
            <div>
                <LazyLoadImage src={clarear} alt="claremento dental em joao pessoa" effect="blur"/>
            </div>
        </section>
    )
}

const ImageCarousel = () => {
    return (
        <div className="carousel-container">
            <h1>Clareamento - Antes e Depois</h1>
            <Carousel
                showThumbs={false} // Remova os indicadores de miniaturas
                infiniteLoop={true} // Faça o carrossel girar em loop
                autoPlay={true} // Defina para reprodução automática
                interval={4000} // Defina o intervalo de tempo entre os slides (em milissegundos)
                showStatus={false}
            >
                <div className="carousel-slide"> {/* Adicione a classe do slide */}
                    <LazyLoadImage src={clarear1} alt="antes e depois de clareamento dentario em joao pessoa" effect="blur" />
                    <p className="carousel-legend">Sorriso mais brilhante e radiante</p> {/* Adicione a classe da legenda */}
                </div>
                <div className="carousel-slide"> {/* Adicione a classe do slide */}
                    <LazyLoadImage src={clarear2} alt="antes e depois de clareamento dentario em joao pessoa" effect="blur" />
                    <p className="carousel-legend">Aumento da autoestima e confiança</p> {/* Adicione a classe da legenda */}
                </div>
                <div className="carousel-slide"> {/* Adicione a classe do slide */}
                    <LazyLoadImage src={clarear3} alt="antes e depois de clareamento dentario em joao pessoa" effect="blur" />
                    <p className="carousel-legend">Personalização do tratamento conforme as necessidades do paciente.</p> {/* Adicione a classe da legenda */}
                </div>
            </Carousel>
        </div>
    )
}

function Profissional() {
    return (
        <section className={styles.profissional}>
            <div>
                <LazyLoadImage src={dentista} alt="melhor dentista em joao pessoa" effect="blur"/>
            </div>
            <div className={styles.profissional__texto}>
                <h2>Waleska é uma profissional que você pode confiar !</h2>
                <p> Uma profissional dedicada e especialista em proporcionar uma experiência confortável e sem dor para seus pacientes. Com sua habilidade excepcional, ela é capaz de explicar de forma clara e compreensível cada procedimento realizado, garantindo que os pacientes entendam completamente o que está acontecendo em seus tratamentos dentários.</p>
                <p> Sua abordagem cuidadosa e atenciosa cria um ambiente acolhedor, no qual o paciente se sente seguro e confiante em receber os melhores cuidados odontológicos. Com essa dentista, você encontrará uma combinação perfeita entre um profissional experiente, um especialista em sua área e alguém que prioriza o bem-estar do paciente em todos os aspectos do tratamento.</p>
                <Link to='./sobre'>
                    <Btn>Saiba mais</Btn>
                </Link>
            </div>
        </section>
    )
}

function Depoimentos() {
    return (
        <section className={styles.depoimentos}>
            <h2>Depoimentos</h2>
            <a href="https://www.google.com/search?q=waleska+caetano+dentista&rlz=1C1FCXM_pt-PTBR998BR998&oq=wale&aqs=chrome.0.69i59j69i57j69i64j69i60l3.882j0j7&sourceid=chrome&ie=UTF-8#lrd=0x7ace71d2c1182ed:0x144e11b63e34417e,1,,,," target='__blank' title='Clique para ver no Google'>
                <img src={depoimentos} alt="dentista bem avaliada em joao pessoa" />
            </a>
        </section>
    )
}